import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { CreateUnit, Unit, UnitNotEliminate } from '../interface/administrative';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ApartmentService extends DataService {
  headers: HttpHeaders;
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')).token,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')).fbToken
    });
    if(this.getBuilding()._id) this.headers.append('buildingId', this.getBuilding()._id);
  }

  fetchApartments(page, limit, search?, find?) {
    const params = search ? `&${search}` : '';
    find = find ? `&search=${find}` : '';
    return this.http
      .get(`${environment.apiUrl}/unit?page=${page}&rowsPerPage=${limit}${params}${find}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  //status unit search
  getUnitsByAdmin(query?: string, page = 0, limit = 10) {
    query = query + '&status=active';
    return this.http.get(`${environment.apiUrl}/admin/v1/units/search?page=${page}&rowsPerPage=${limit}${query}`);
  }

  getUnits(page, limit, search?, find?, status?: string) {
    const params = search ? `&${search}` : '';
    find = find ? `&unit=${find}` : '';
    const isStatus = status ? `&status=${status}` : '';

    return this.http
      .get(`${environment.apiUrl}/admin/v1/units/search?page=${page}&rowsPerPage=${limit}${params}${find}${isStatus}`)
      .toPromise()
      .then((res: any) => {
        return res.data || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  async onlyNameAndUnit(units) {
    return units.map(function (element) {
      return (element = {
        id: element.id,
        number: element.number,
      });
    });
  }

  fetchApartmentsSimplify(unit?: string, status?: string, all?) {
    const isStatus = status ? `&status=${status}` : '';
    const search = unit ? `&search=${unit}` : '';
    all = all ? `&all=all` : '';
    return this.http
      .get(`${environment.apiUrl}/admin/v1/units/search?${search}${isStatus}${all}`)
      .toPromise()
      .then((res: any) => {
        return res.data.units.docs || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  callApiUnit(unit?) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.apiUrl}/unit-simplify${unit ? `?unit=${unit.toUpperCase()}` : ''}`)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createUnitByBuilding(unit: CreateUnit): Observable<Unit> {
    return this.http.post<Unit>(`${environment.apiUrl}/admin/v1/units/create-unit`, unit, { headers: this.headers });
  }

  storeApartment(data) {
    const buildingId = this.getBuilding()._id;
    data = {
      ...data,
      building: { _id: buildingId },
    };
    return this.http
      .post(`${environment.apiUrl}/unit`, data)
      .toPromise()
      .then((res) => {
        console.log('ress: ', res);
      })
      .catch((err) => {
        console.log('errr: ', err);
        return Promise.reject(err);
      });
  }

  viewApartment(id) {
    return this.http.get(`${environment.apiUrl}/admin/v1/units/unit/${id}`).pipe(map((data: any) => data.data || []));
  }

  updateApartment(data) {
    return this.http
      .put(`${environment.apiUrl}/admin/v1/units/updatedUnit`, data)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  uploadCSV(file) {
    const buildingId = this.getBuilding()._id;
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    formdata.append('buildingId', buildingId);
    return this.http
      .post(`${environment.apiUrl}/unit/upload-csv`, formdata)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  deleteUnitStatus(unitId: string) {
    return this.http.delete(`${environment.apiUrl}/admin/v1/units/${unitId}`, { headers: this.headers });
  }

  deleteUnitStatusFromSuperAdmin(buildingId:string, unitId: string) {
    // Because as a superuser we do not have an associated building in the headers but the service needs an id to search for the unit, we create a temporary header with the id of the building to which the selected apartment belongs.
    let customHeaders = this.headers;
    customHeaders = customHeaders.append('buildingId',buildingId);
    return this.http.delete(`${environment.apiUrl}/admin/v1/units/${unitId}`, { headers: customHeaders });
  }

  getNotEliminateUnit(unitId: string): Observable<UnitNotEliminate> {
    return this.http
      .get<UnitNotEliminate>(`${environment.apiUrl}/admin/v1/units/notEliminate/${unitId}`, { headers: this.headers })
      .pipe(map((res: any) => res.data));
  }

  sendWhatsappInvitation(unitId:string) {
    return this.http.get(`${environment.apiUrl}/admin/v1/call/unit/${unitId}/send-notification`,{ headers: this.headers });
  }
}
